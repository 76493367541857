<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "CreatingYourWritingEnvironment",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="CreatingYourWritingEnvironment">
      <SuawParagraph
        text="Do you have a place where you write consistently? If not, this guide will help you create a focused environment so you can be at your best when writing."
      />
      <SuawHeading level="3" content="Location, Location, Location" />
      <SuawParagraph
        text="First, your writing environment should be a place you feel safe. It should engage your thoughts and help you focus. Ideally it should be a place where you can recharge, practice your craft, and find inspiration."
      />
      <SuawParagraph
        text="Ask yourself the following questions: Where do I feel the most comfortable in my home? What type of environment focuses my mind? What ignites my imagination? On the flip side, what distracts me? What makes my writing practice harder than it needs to be?"
      />
      <SuawParagraph
        text="Whether it’s in your home or in a cafe near work or school, having a place that’s easy to get to increases the likelihood that you’ll actually go there and write."
      />
      <SuawParagraph text="Here are a few points you’ll want to consider:" />
      <SuawParagraph
        text="When do you plan to write? Do you prefer writing in the morning, afternoon, in the dead of night? If you’re writing during the day, ample natural lighting can help you stay focused. If you like to write at night, a full-spectrum light can do the same. Or maybe you’re a night owl who likes working in the dark. Whatever time you’re the most focused and productive, carve out some writing time in that part of the day."
      />
      <SuawParagraph
        text="Factor in your ideal noise level. If you find background noise distracting, seek out a quiet location. If you like to listen to music, remember to bring your headphones to your writing session. If the active buzz of a cafe helps you focus, plan to write at your favorite coffee shop."
      />
      <SuawParagraph
        text="How much can you control or impact the space? Do you have space to post notes about your story, or visual references for your settings or characters? Littering your writing space with inspiration can give you that extra creative boost."
      />
      <SuawParagraph
        text="Creature comforts matter. Is your chair comfortable enough to sit in for at least an hour? Do you have the right layers to keep yourself comfortable while you write? Are food and drink within arm’s reach, so you don’t need to take extra time to get up and eat?"
      />
      <SuawParagraph
        text="Is there anything visual that helps you focus? Do you like to look at something specific, like an object that triggers your imagination? How about a beautiful view? Does it inspire you to watch people around you? Figure out whether you need sensory reduction or stimulation to get those words down on paper."
      />
      <SuawHeading level="3" content="Remember to Gather Your Tools" />
      <SuawParagraph
        text="<ul><li>Water – Keep your mind sharp by staying hydrated</li><li>Notepad and pen for taking notes</li><li>Computer charger</li><li>An extra layer in case it gets cold</li><li>Headphones and an inspiring playlist of your favorite writing music</li><li>A writing trinket – Many successful writers keep a special item on their desk whenever they write</li><li>Snacks – Don’t write while hungry!</li><li>Reliable WiFi – We recommend investing in a mobile WiFi hotspot so you can provide your own WiFi when necessary</li></ul>"
      />
      <SuawHeading level="3" content="Setting Expectations for Others" />
      <SuawParagraph text="This is especially important for those who are just starting out with their writing practice." />
      <SuawParagraph
        text="Before you start writing, tell anyone who may call that you are going to be busy. Reach out to your spouse, coworkers, or anyone else who may interrupt you and let them know you’ll get back to them after you finish your writing session. If you are plugged into the digital world, consider posting on social media that you are going dark for an hour."
      />
      <SuawParagraph
        text="Declaring your intentions to the world is a powerful way to focus yourself. Make it known that you will be unavailable for mundane tasks during your writing time. This is your time to bring focus to your craft without getting distracted by other people’s needs."
      />
      <SuawHeading level="3" content="One Last Thing: Go Dark" />
      <SuawParagraph
        text="Once you’re completely prepared and ready to start writing, all you have to do is put your phone on airplane mode and get started. It’s as simple as that."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
